






import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as common from '@/operations/common';
import * as blockchainMethod from '@/operations/blockchainMethod';

@Component({
  components: {},
})
export default class BaseCollectionInformation extends Vue {
  @Prop() item;
  @Prop() showOnSale;

  private onSaleStatus = false;
  @Watch('item', { immediate: true }) async onitemChanged(): Promise<void> {
    this.onSaleStatus = await blockchainMethod.listedStatusTokenId(this.$connex, this.item.tokenId, this.$ENV.AUCTION_COMMUNITY_NFT);
  }
}
