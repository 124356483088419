






















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { picasso } from '@vechain/picasso';
import * as common from '@/operations/common';

import * as blockchainMethod from '@/operations/blockchainMethod';

@Component({
  components: {},
})
export default class BaseModalPurchasePreview extends Vue {
  @Prop() price;
  @Prop() payment;

  public async mounted(){
    console.log(this.price);
  }
}
