


















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as common from '@/operations/common';

@Component({
  components: {},
})
export default class BaseTokenName extends Vue {
  @Prop(String) tokenName;
  @Prop(Number) tokenId;
  @Prop(Number) rank;
  @Prop(Number) rarity;
}
