

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class BaseCard extends Vue {
  @Prop(String) linkURL;
  @Prop(String) imageURL;
}
