












































import { Component, Vue } from 'vue-property-decorator';

import 'bootstrap-vue/dist/bootstrap-vue.css';

import * as blockchainMethod from './operations/blockchainMethod';
import AccountSign from '@/types/AccountSign';

import modalEvent from './components/util/ModalEvent.vue';
@Component({
  components: { modalEvent },
})
export default class App extends Vue {
  public version = '';

  public maintenanceStatus = true;

  public accountSign: AccountSign = {
    address: '',
    vetBalance: 0,
    wovBalance: 0,
    wovCommunityBalance: 0,
    signedIn: false,
    approvedCommunityAuction: false,
  };

  public async monitorBlock(): Promise<void> {
    let tick = this.$connex.thor.ticker();
    tick.next().then(() => {
      // this.monitorLog();
      this.monitorBlock();
    });
  }

  // signAgreement
  public async signAgreement(): Promise<void> {
    blockchainMethod.sign(this.$connex, this.$WEBSITE_URL).then((result) => {
      // console.log(result.annex.signer);
      this.$store.commit('setAccountAddress', result.annex.signer);
      this.accountSign = this.$store.getters.account;
      this.$store.commit('setAccountStatus', true);
      this.$store.dispatch('refreshAccountSign');
      this.$store.dispatch('refreshAccountCommunityNFT');
    });
  }

  public async unsync(): Promise<void> {
    localStorage.removeItem('wallet');
    window.location.replace('/');
  }

  // Default Function
  public async created(): Promise<void> {
    this.$store.state.env = this.$ENV;

    if (localStorage.version) {
      this.version = localStorage.version;
      if (this.version != this.$ENV.VERSION) {
        localStorage.removeItem('wallet');
        localStorage.removeItem('version');
        localStorage.version = this.$ENV.VERSION;
      }
    } else {
      localStorage.version = this.$ENV.VERSION;
    }

    if (localStorage.wallet) {
      const res = JSON.parse(localStorage.wallet);
      this.$store.commit('setAccountAddress', res.address);
      this.$store.dispatch('refreshAccountSign');
      this.accountSign = this.$store.getters.account;
      this.$store.dispatch('refreshAccountCommunityNFT');
    }

    this.monitorBlock();

    // check to see which connex the client is currently using. Either v1 or v2
  }
}
