






















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { picasso } from '@vechain/picasso';
import * as common from '@/operations/common';
import * as readVIP181Core from '@/operations/readVIP181Core';

import * as blockchainMethod from '@/operations/blockchainMethod';
import * as utils from '@/operations/utils.js';

@Component({
  components: {},
})
export default class BaseAccountPreview extends Vue {
  @Prop() item;
  @Prop(String) title;

  private accountPicture = '';
  private accountName = '';
  private registered = false;
  private accountInfo = [] as any;
  private accountAddress = '';

  get svg() {
    return {
      float: 'left',
      width: '50px',
      height: '50px',
      background: "no-repeat url('data:image/svg+xml;utf8," + picasso(this.accountAddress) + "')",
    };
  }

  get shortAddress() {
    return this.accountAddress.substring(0, 10) + '...' + this.accountAddress.substring(36);
  }

  @Watch('item', { immediate: true }) async onItemChanged(): Promise<void> {
    this.accountAddress = '';
    if (this.title === 'Creator') {
      this.accountAddress = this.item.tokenCreator;
    } else {
      this.accountAddress = await readVIP181Core.getOwnerOf(
        this.$connex,
        this.$ENV.WOV_COMMUNITY_CARD_NFT,
        this.item.tokenId
      );
    }
    this.accountInfo = await blockchainMethod.getAccountInfo(
      this.$connex,
      this.$ENV.WOV_ACCOUNT_CONTRACT,
      this.accountAddress
    );

    if (this.accountInfo[0] > 0) {
      this.accountPicture = 'https://worldofv-marketplace.mypinata.cloud/ipfs/' + this.accountInfo[2];
    }

    if (parseInt(this.accountInfo[0]) > 0) {

      this.registered = true;
      this.accountName = this.accountInfo[3];
    } else {
      this.registered = false;
    }
  }
  public async redirect(): Promise<void> {
    let address = this.accountAddress;
    let url = utils.profileUrl(address);
    this.$router.push({ path: url });
  }
}
