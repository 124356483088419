
async function approve(connex : Connex, addressContract: string, signerAddress: string, _spender: string, _value: number): Promise<Connex.Vendor.TxResponse> {

    const ABI = {
        "constant": false,
        "inputs": [{
                "name": "_spender",
                "type": "address"
            },
            {
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [{
            "name": "",
            "type": "bool"
        }],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    }
    // eslint-disable-next-line
    const method = connex.thor.account(addressContract).method(ABI)
    // eslint-disable-next-line
    const transferClause: any[] = [];
    transferClause.push(method.asClause(_spender, _value))

    const signingService = connex.vendor.sign('tx', transferClause)
    signingService
        .signer(signerAddress) // Enforce signer
        .comment('You need to approve the auction contract to handle your VTHO token')
    // eslint-disable-next-line
    const transactionInfo = await signingService.request()
    return transactionInfo
}
async function allowance(connex : Connex, addressContract: string, _owner: string, _spender: string): Promise<number> {
    const ABI = {
        "constant": true,
        "inputs": [{
                "name": "_owner",
                "type": "address"
            },
            {
                "name": "_spender",
                "type": "address"
            }
        ],
        "name": "allowance",
        "outputs": [{
            "name": "",
            "type": "uint256"
        }],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    }
    // eslint-disable-next-line
    const method = connex.thor.account(addressContract).method(ABI)
    const transfer = await method.call(_owner, _spender)
    return transfer.decoded[0];
}
async function balanceOf(connex : Connex, addressContract: string, _address: string): Promise<number> {
    
    const ABI = {
        "inputs": [{
            "internalType": "address",
            "name": "account",
            "type": "address"
        }],
        "name": "balanceOf",
        "outputs": [{
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
        }],
        "stateMutability": "view",
        "type": "function"
    }
    // eslint-disable-next-line
    const method = connex.thor.account(addressContract).method(ABI)

    const transfer = await method.call(_address)

    return transfer.decoded[0];
}

export {
    approve,
    allowance,
    balanceOf
}
