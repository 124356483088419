








import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as common from '@/operations/common';

@Component({
  components: {},
})
export default class BaseCollectionInformation extends Vue {
  @Prop() item;

}
