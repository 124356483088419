























































































































import { Component, Vue } from 'vue-property-decorator';
import { bus } from '@/main';

import * as blockchainMethod from '@/operations/blockchainMethod';
import axios, { AxiosResponse } from 'axios';
import PinataResponse from 'src/types/PinataResponse';
import AccountSign from '@/types/AccountSign';
import 'vue-slider-component/theme/antd.css';
import Multiselect from 'vue-multiselect';
import { identity } from 'cypress/types/lodash';

@Component({
  components: { Multiselect },
})
export default class BaseCreateToken extends Vue {
  public editionType = [
    {
      type: 'singleEdition',
      name: 'Single Edition',
    },
    {
      type: 'multipleEdition',
      name: 'Multiple Editions',
    },
    // {
    //   type: 'openEditions',
    //   name: 'Open Editions',
    // },
  ];

  public isFiltering = 0;

  public formEditionType = this.editionType[0].type;
  public formTokenName = '';
  public formTokenDescription = '';
  public formTokenCollectionName = '';
  public filePreview?: string;
  public file!: File;
  public showPreview = false;
  public previewKey = 0;
  public uploadLabel = ' Choose File ';
  public maxSize = 8 * 1048576;
  public royaltyRate = 0;
  public numberOfEdition = 1;
  public categories = null;
  public categoriesData = ['Art', 'Photos', 'Games', 'Collectibles', 'Trading Cards', 'Sports', 'Utility', 'Meme'];

  get accountSignCompute(): AccountSign {
    return this.$store.getters.account;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get env() {
    return this.$store.getters.env;
  }

  get versionEdition(): boolean {
    // `this` points to the vm instance
    return this.formEditionType == 'multipleEdition';
  }

  public royaltiesChange (royaltiesChange: number): void {
    if (royaltiesChange == 5){
      this.royaltyRate = 5;
    } else if (royaltiesChange == 10){
       this.royaltyRate = 10;
    } else if (royaltiesChange == 15){
       this.royaltyRate = 15;
    } else if (royaltiesChange == 20){
       this.royaltyRate = 20;
    }
  }

  public onFileChange(e: Event | undefined): void {
    if (e != undefined) {
      const target = e.target as HTMLInputElement;
      this.file = (target.files as FileList)[0];
      if (this.file.size >= this.maxSize) {
        alert('File is too big!');
      } else {
        this.filePreview = URL.createObjectURL(this.file);
        this.showPreview = true;
        this.uploadLabel = ' Change File ';
        this.previewKey++;
      }
    }
  }


  public async ipfsUpload(event: Event): Promise<void> {

    event.preventDefault();
    if (this.file != undefined && this.file.size <= this.maxSize && this.formTokenName != '' && this.formTokenDescription != '' && this.formTokenCollectionName != '') {
      let data = this.file;
      if (data.size > 0) {
        const url = process.env.VUE_APP_IPFS_BASE_URL + '/pinning/pinFileToIPFS';
        let form = new FormData();
        form.append('file', data);

        //You'll need to make sure that the metadata is in the form of a JSON object that's been converted to a string
        //metadata is optional
        const pinataOptions = JSON.stringify({ cidVersion: 0 });
        form.append('pinataOptions', pinataOptions);
        const pinataMetadata = JSON.stringify({
          name: this.formTokenName,
          keyValues: {
            categories: this.categories,
            creator: this.accountSignCompute.address,
            collectionName: this.formTokenCollectionName,
            curatedArt: false,
            createdAt: Date.now() / 1000,
          },
        });
        form.append('pinataMetadata', pinataMetadata);
        let response = await axios.post<FormData, AxiosResponse<PinataResponse>>(url, form, {
          maxBodyLength: Infinity,
          headers: {
            'Content-Type': `multipart/form-data; boundary=${(form as any)._boundary}`,
            Authorization: `Bearer ${process.env.VUE_APP_IPFS_JWT}`,
          },
        });
        if (response.status == 200) {
          const pinataContent = {
            name: this.formTokenName,
            description: this.formTokenDescription,
            creator: this.accountSignCompute.address,
            data: '',
            tags: [],
            collectionName: this.formTokenCollectionName,
            category: '',
            fileHash: `${response.data.IpfsHash}`,
            fileType: data.type
          };
          const payload = {
            pinataOptions: pinataOptions,
            pinataMetadata: pinataMetadata,
            pinataContent: pinataContent,
          };

          const jsonUrl = `${process.env.VUE_APP_IPFS_BASE_URL}/pinning/pinJSONToIPFS`;
          let jsonResponse = await axios.post<unknown, AxiosResponse<PinataResponse>>(jsonUrl, payload, {
            headers: {
              Authorization: `Bearer ${process.env.VUE_APP_IPFS_JWT}`,
            },
          });
          if (jsonResponse.data.IpfsHash && jsonResponse.data.IpfsHash.length > 0) {
            await this.mintToken(
              jsonResponse.data.IpfsHash,
              this.formTokenCollectionName,
              this.formTokenName,
              this.royaltyRate,
              pinataContent.fileHash
            );
          }
        }
      } else{
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      }
    } else{
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  }

  public async mintToken(ipfsHash: string, collectionName: string, woviesName: string, royalty: number, fileHash: string): Promise<void> {
    await blockchainMethod
      .mintToken(
        this.$connex,
        this.$VIP191SERVICE,
        this.$VIP191PAYER,
        'Minting transaction',
        this.$ENV.WOV_COMMUNITY_CARD_NFT,
        this.accountSignCompute.address,
        ipfsHash,
        fileHash,
        collectionName,
        woviesName,
        royalty,
        this.numberOfEdition
      )
      .then((result) => {
        bus.$emit('mintEvent', result.txid);
        bus.$emit('showToast', true);
        this.$router.push({ path: `owner/${this.accountSignCompute.address}` });
      });
  }
}

