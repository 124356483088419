













import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ImageModal extends Vue {
  @Prop(String) fileClasses;
  @Prop(String) fileUrl;
  @Prop(String) modalTitle;
  @Prop(String) modalKey;
  @Prop(String) fileType;

  data() {
    return {
      hideCancel: true,
    };
  }
  showModal() {
    this.$bvModal.show(this.modalKey);
  }

  hideModal() {
    this.$bvModal.hide(this.modalKey);
  }

}
