import Account from '@/types/Account';
import AccountSign from '@/types/AccountSign';
import Marketplace from '@/types/Marketplace';
import State from '@/types/State';

export default {
    setAccountAddress(state: State, payload: string){
        state.account.address = payload;
    },
    setAccountStatus(state: State, signedIn: boolean){
        state.account.signedIn = signedIn;
    },
    setAccountSign(state: State, account: AccountSign){
        state.account = account;
    },
    setAccountwovCommunityBalanceOf(state: State, wovCommunityBalance: number){
        state.account.wovCommunityBalance = wovCommunityBalance;
    },
    setWoVBalance(state: State, wovBalance: number){
        state.account.wovBalance = wovBalance;
    },
    setAccountCommunityNFT(state: State, accountCommunityNFT: Account[]){
        state.accountCommunityNFT = accountCommunityNFT;
    },
    updateApprovalStatus(state: State, payload: {communityAuction: boolean }){
        state.account.approvedCommunityAuction = payload.communityAuction;
    },
    setAuctionList(state: State, auctions: Marketplace[]){
        state.marketplace = auctions;
    },
    pushAuction(state: State, auction: Marketplace){
        state.marketplace.push(auction);
    }
}
