import * as connexTransaction from './connexTransaction';

import TokenClaimList from '../types/TokenClaimList';

async function sign(connex: Connex, url: string): Promise<Connex.Vendor.CertResponse> {
  const result = await connex.vendor
    .sign('cert', {
      purpose: 'identification',
      payload: {
        type: 'text',
        content: 'Please select a wallet and grant access to the Marketplace dApp.',
      },
    })
    .link(url)
    .request();

  return result;
}

async function getMyCreations(
  connex: Connex,
  addressContract: string,
  signerAddress: string,
  balanceOf: number
): Promise<Connex.VM.Explainer> {
  const transferClause = [] as any;

  // Prepare energy transfer clause

  const ABI = {
    inputs: [
      {
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'index',
        type: 'uint256',
      },
    ],
    name: 'tokenOfOwnerByIndex',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  };
  const transferMethod = connex.thor.account(addressContract).method(ABI);

  for (let index = 0; index < balanceOf; index++) {
    transferClause.push(transferMethod.asClause(signerAddress, index));
  }
  return connex.thor.explain(transferClause);
}

async function getMyCollection(
  connex: Connex,
  addressContract: string,
  signerAddress: string,
  start: number,
  end: number
): Promise<Connex.VM.Explainer> {
  const transferClause = [] as any;

  // Prepare energy transfer clause

  const ABI = {
    inputs: [
      {
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'index',
        type: 'uint256',
      },
    ],
    name: 'tokenOfOwnerByIndex',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
    constant: true,
  };
  const transferMethod = connex.thor.account(addressContract).method(ABI);
  for (let index = start; index < end; index++) {
    transferClause.push(transferMethod.asClause(signerAddress, index));
  }
  return connex.thor.explain(transferClause);
}

async function getOnSale(
  connex: Connex,
  addressContract: string,
  signerAddress: string,
  wovCommunityBalance: number
): Promise<Connex.VM.Explainer> {
  const transferClause = [] as any;

  // Prepare energy transfer clause

  const ABI = {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'listedMap',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
    constant: true,
  };
  const transferMethod = connex.thor.account(addressContract).method(ABI);
  for (let index = 0; index < wovCommunityBalance; index++) {
    transferClause.push(transferMethod.asClause(index));
  }
  return connex.thor.explain(transferClause);
}
async function listedStatusTokenId(connex: Connex, tokenId: number, addressContract: string): Promise<boolean> {
  const ABI = {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'listedMap',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
    constant: true,
  };
  // eslint-disable-next-line
  const method = connex.thor.account(addressContract).method(ABI);
  const transfer = await method.call(tokenId);

  return transfer.decoded[0];
}
async function mintToken(
  connex: Connex,
  service: string,
  payer: string,
  comment: string,
  addressContract: string,
  signerAddress: string,
  ipfsHash: string,
  fileHash: string,
  collectionName: string,
  woviesName: string,
  royalty: number,
  numberOfEdition: number
): Promise<Connex.Vendor.TxResponse> {
  const ABI = {
    inputs: [
      {
        internalType: 'string',
        name: 'ipfsMetadata',
        type: 'string',
      },
      {
        internalType: 'string',
        name: 'ipfsFileHash',
        type: 'string',
      },
      {
        internalType: 'string',
        name: 'collectionString',
        type: 'string',
      },
      {
        internalType: 'string',
        name: 'woviesString',
        type: 'string',
      },
      {
        internalType: 'uint256',
        name: 'multipleEdition',
        type: 'uint256',
      },
      {
        internalType: 'uint8',
        name: 'commission',
        type: 'uint8',
      },
    ],
    name: 'mint',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  };
  // eslint-disable-next-line
  let transferClause: any[] = [];
  const method = connex.thor.account(addressContract).method(ABI);
  transferClause.push(method.asClause(ipfsHash, fileHash, collectionName, woviesName, numberOfEdition, royalty));

  const result = await connexTransaction.feeDelegationSignTx(
    connex,
    service,
    payer,
    signerAddress,
    transferClause,
    comment
  );
  return result;
}

async function buyTokenId(
  connex: Connex,
  service: string,
  payer: string,
  comment: string,
  addressContract: string,
  signerAddress: string,
  tokenId: number,
  amountEVM: string
): Promise<Connex.Vendor.TxResponse> {
  const ABI = {
    inputs: [
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
      {
        internalType: 'string',
        name: 'paymentType',
        type: 'string',
      },
    ],
    name: 'buy',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
    payable: true,
  };

  // eslint-disable-next-line
  const bidMethod = connex.thor.account(addressContract).method(ABI);
  bidMethod.value(amountEVM);
  // eslint-disable-next-line
  const transferClause = bidMethod.asClause(tokenId, 'VET');

  // eslint-disable-next-line
  const result = await connexTransaction.feeDelegationSignTx(
    connex,
    service,
    payer,
    signerAddress,
    [transferClause],
    comment
  );
  return result;
}

async function updateTokenIdPrice(
  connex: Connex,
  service: string,
  payer: string,
  comment: string,
  addressContract: string,
  signerAddress: string,
  tokenId: number,
  price: string,
  payment: string
): Promise<Connex.Vendor.TxResponse> {
  const ABI = {
    inputs: [
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_price',
        type: 'uint256',
      },
      {
        internalType: 'string',
        name: 'paymentType',
        type: 'string',
      },
    ],
    name: 'updatePrice',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  };
  // eslint-disable-next-line
  const method = connex.thor.account(addressContract).method(ABI);

  // eslint-disable-next-line
  const transferClause = method.asClause(tokenId, price, payment);
  // eslint-disable-next-line
  const result = await connexTransaction.feeDelegationSignTx(
    connex,
    service,
    payer,
    signerAddress,
    [transferClause],
    comment
  );
  return result;
}

async function updateListingStatus(
  connex: Connex,
  service: string,
  payer: string,
  comment: string,
  addressContract: string,
  signerAddress: string,
  tokenId: number
): Promise<Connex.Vendor.TxResponse> {
  const ABI = {
    inputs: [
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
      {
        internalType: 'bool',
        name: 'shouldBeListed',
        type: 'bool',
      },
    ],
    name: 'updateListingStatus',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  };
  // eslint-disable-next-line
  const method = connex.thor.account(addressContract).method(ABI);

  // eslint-disable-next-line
  const transferClause = method.asClause(tokenId, false);
  // eslint-disable-next-line
  const result = await connexTransaction.feeDelegationSignTx(
    connex,
    service,
    payer,
    signerAddress,
    [transferClause],
    comment
  );
  return result;
}

async function setApprovalForAll(
  connex: Connex,
  service: string,
  payer: string,
  comment: string,
  addressContract: string,
  signerAddress: string,
  operator: string
): Promise<Connex.Vendor.TxResponse> {
  const ABI = {
    inputs: [
      {
        internalType: 'address',
        name: 'operator',
        type: 'address',
      },
      {
        internalType: 'bool',
        name: 'approved',
        type: 'bool',
      },
    ],
    name: 'setApprovalForAll',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  };

  // eslint-disable-next-line
  const method = connex.thor.account(addressContract).method(ABI);
  const transferClause = method.asClause(operator, true);

  const result = await connexTransaction.feeDelegationSignTx(
    connex,
    service,
    payer,
    signerAddress,
    [transferClause],
    comment
  );
  return result;
}

async function safeTransferFromWithoutFee(
  connex: Connex,
  service: string,
  payer: string,
  addressContract: string,
  signerAddress: string,
  from: string,
  to: string,
  tokenId: number,
  comment: string
): Promise<Connex.Vendor.TxResponse> {

  const ABI = {
    inputs: [
      {
        internalType: 'address',
        name: 'from',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'to',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'safeTransferFrom',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  };

  // eslint-disable-next-line
  const method = connex.thor.account(addressContract).method(ABI);
  const transferClause = [method.asClause(from, to, tokenId)];

  // eslint-disable-next-line
  const result = await connexTransaction.feeDelegationSignTx(
    connex,
    service,
    payer,
    signerAddress,
    transferClause,
    comment
  );
  return result;
}
async function safeTransferFromWithFee(
  connex: Connex,
  service: string,
  payer: string,
  addressContract: string,
  addressContractBurn: string,
  signerAddress: string,
  from: string,
  to: string,
  tokenId: number,
  comment: string
): Promise<Connex.Vendor.TxResponse> {
  const transferClause = [] as any;
  const ABIvip180 = {
    inputs: [
      {
        internalType: 'uint256',
        name: 'burnQuantity',
        type: 'uint256',
      },
    ],
    name: 'burn',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  };

  // eslint-disable-next-line
  const methodBurn = connex.thor.account(addressContractBurn).method(ABIvip180);
  transferClause.push(methodBurn.asClause('0x56bc75e2d63100000'));

  const ABI = {
    inputs: [
      {
        internalType: 'address',
        name: 'from',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'to',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'safeTransferFrom',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  };

  // eslint-disable-next-line
  const method = connex.thor.account(addressContract).method(ABI);
  transferClause.push(method.asClause(from, to, tokenId));

  // eslint-disable-next-line
  const result = await connexTransaction.feeDelegationSignTx(
    connex,
    service,
    payer,
    signerAddress,
    transferClause,
    comment
  );
  return result;
}

async function registerAccount(
  connex: Connex,
  service: string,
  payer: string,
  comment: string,
  addressContract: string,
  signerAddress: string,
  ipfsHash: string,
  fileHash: string,
  accountName: string,
  accountType: number
): Promise<Connex.Vendor.TxResponse> {
  const ABI = {
    inputs: [
      {
        internalType: 'string',
        name: 'ipfsMetadata',
        type: 'string',
      },
      {
        internalType: 'string',
        name: 'ipfsFileHash',
        type: 'string',
      },
      {
        internalType: 'string',
        name: 'accountName',
        type: 'string',
      },
      {
        internalType: 'uint8',
        name: 'accountType',
        type: 'uint8',
      },
    ],
    name: 'registerAccount',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  };
  // eslint-disable-next-line
  let transferClause: any[] = [];
  const method = connex.thor.account(addressContract).method(ABI);

  transferClause = [method.asClause(ipfsHash, fileHash, accountName, accountType), ...transferClause];

  const result = await connexTransaction.feeDelegationSignTx(
    connex,
    service,
    payer,
    signerAddress,
    transferClause,
    comment
  );
  return result;
}

async function getAccountInfo(connex: Connex, addressContract: string, address: string) {
  const ABI = {
    inputs: [
      {
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
    ],
    name: 'getAccountPropertiesByAddress',
    outputs: [
      {
        internalType: 'uint8',
        name: '',
        type: 'uint8',
      },
      {
        internalType: 'string',
        name: '',
        type: 'string',
      },
      {
        internalType: 'string',
        name: '',
        type: 'string',
      },
      {
        internalType: 'string',
        name: '',
        type: 'string',
      },
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
    constant: true,
  };
  // eslint-disable-next-line
  const method = connex.thor.account(addressContract).method(ABI);
  const transfer = await method.call(address);
  return transfer.decoded;
}

async function woviesCountOfCollection(connex: Connex, addressContract: string, tokenId: number): Promise<number> {
  const collectionId = Math.floor(tokenId / 100000000) * 100000000;
  const ABI = {
    inputs: [
      {
        internalType: 'uint256',
        name: 'collectionId',
        type: 'uint256',
      },
    ],
    name: 'woviesCountOfCollection',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  };
  // eslint-disable-next-line
  const method = connex.thor.account(addressContract).method(ABI);
  const transfer = await method.call(collectionId);
  return transfer.decoded[0];
}

async function listedStatus(connex: Connex, addressContract: string, tokenId: number): Promise<boolean> {
  // Prepare energy transfer clause

  const ABI = {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'listedMap',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
    constant: true,
  };
  const method = connex.thor.account(addressContract).method(ABI);
  const transfer = await method.call(tokenId);
  return transfer.decoded[0];
}

async function getTokenIdPrice(connex: Connex, addressContract: string, tokenId: number): Promise<number> {
  // Prepare energy transfer clause

  const ABI = {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'price',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
    constant: true,
  };
  const method = connex.thor.account(addressContract).method(ABI);
  const transfer = await method.call(tokenId);
  return transfer.decoded[0];
}

async function getProfileId(connex: Connex, addressContract: string, address: string): Promise<number> {
  const ABI = {
    inputs: [
      {
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'tokenOfAccount',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
    constant: true,
  };
  // eslint-disable-next-line
  const method = connex.thor.account(addressContract).method(ABI);
  const transfer = await method.call(address);
  return transfer.decoded[0];
}

async function getCollectionCountOfCreator(connex: Connex, addressContract: string, address: string): Promise<number> {
  const ABI = {
    inputs: [
      {
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
    ],
    name: 'collectionCountOfCreator',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  };
  // eslint-disable-next-line
  const method = connex.thor.account(addressContract).method(ABI);
  const transfer = await method.call(address);
  return transfer.decoded[0];
}

async function getWoviesCountOfCollection(
  connex: Connex,
  addressContract: string,
  collectionId: number
): Promise<number> {
  const ABI = {
    inputs: [
      {
        internalType: 'uint256',
        name: 'collectionId',
        type: 'uint256',
      },
    ],
    name: 'woviesCountOfCollection',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  };
  // eslint-disable-next-line
  const method = connex.thor.account(addressContract).method(ABI);
  const transfer = await method.call(collectionId);
  return transfer.decoded[0];
}

async function claimedStatus(
  connex: Connex,
  addressContract: string,
  tokenId: number,
  category: number
): Promise<Connex.VM.Explainer> {
  const ABI = {
    inputs: [
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'category',
        type: 'uint256',
      },
    ],
    name: 'claimedTokenStatus',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  };
  // eslint-disable-next-line
  const method = connex.thor.account(addressContract).method(ABI);
  const transfer = await method.call(tokenId, category);
  return transfer.decoded[0];
}

async function claimVehashes(
  connex: Connex,
  service: string,
  payer: string,
  comment: string,
  addressContract: string,
  signerAddress: string,
  tokenArray: TokenClaimList[],
  tokenType: string,
  amountEVM: string
): Promise<Connex.Vendor.TxResponse> {
  const ABI = {
    inputs: [
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
      {
        internalType: 'string',
        name: 'tokenType',
        type: 'string',
      },
    ],
    name: 'mintToken',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  };
  // eslint-disable-next-line
  let transferClause: any[] = [];
  const method = connex.thor.account(addressContract).method(ABI);
  method.value(amountEVM);
  for (let index = 0; index < tokenArray.length; index++) {
    const token = tokenArray[index];

    if (token?.tokenId) {
      transferClause.push(method.asClause(tokenArray[index].tokenId, tokenType));
    }
  }

  const result = await connexTransaction.feeDelegationSignTx(
    connex,
    service,
    payer,
    signerAddress,
    transferClause,
    comment
  );
  return result;
}
async function buyVehashes(
  connex: Connex,
  service: string,
  payer: string,
  comment: string,
  addressContract: string,
  signerAddress: string,
  numberOfMint: number,
  amountEVM: string
): Promise<Connex.Vendor.TxResponse> {
  const ABI = {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "seed",
        "type": "uint256"
      }
    ],
    "name": "purchaseTokenRandom",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  };
  // eslint-disable-next-line
  let transferClause: any[] = [];
  const method = connex.thor.account(addressContract).method(ABI);
  method.value(amountEVM);
  for (let index = 0; index < numberOfMint; index++) {
    const randomSeed = Math.floor(Math.random() * 1000000000000);
    console.log(randomSeed)
    transferClause.push(method.asClause(randomSeed));
  }

  const result = await connexTransaction.feeDelegationSignTx(
    connex,
    service,
    payer,
    signerAddress,
    transferClause,
    comment
  );
  return result;
}
async function batchClaimedStatus(
  connex: Connex,
  addressContract: string,
  tokens: [
    {
      tokenId: number;
      category: number;
    }
  ]
): Promise<Connex.VM.Explainer> {
  const transferClause: Connex.VM.Clause[] = [];

  const ABI = {
    inputs: [
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'category',
        type: 'uint256',
      },
    ],
    name: 'claimedTokenStatus',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  };

  const transferMethod = connex.thor.account(addressContract).method(ABI);

  for (const token of tokens) {
    transferClause.push(transferMethod.asClause(token.tokenId, token.category));
  }

  return connex.thor.explain(transferClause);
}

async function batchCheckIsOnAuction(
  connex: Connex,
  addressContract: string,
  tokens: [
    {
      tokenId: number;
      category: number;
    }
  ]
): Promise<Connex.VM.Explainer> {
  const transferClause: Connex.VM.Clause[] = [];

  const ABI = {
    inputs: [
      {
        internalType: 'uint256',
        name: '_tokenId',
        type: 'uint256',
      },
    ],
    name: 'isOnAuction',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
    constant: true,
  };

  const transferMethod = connex.thor.account(addressContract).method(ABI);

  for (const token of tokens) {
    transferClause.push(transferMethod.asClause(token.tokenId));
  }

  return connex.thor.explain(transferClause);
}

async function purchaseWithWOV(
  connex: Connex,
  service: string,
  payer: string,
  comment: string,
  addressContract: string,
  signerAddress: string,
  _tokenId: number,
  amountEVM: string,
  vip180address: string,
  paymentType: string,
): Promise<Connex.Vendor.TxResponse> {

  const ABI = {
    inputs: [
      {
        internalType: 'address',
        name: 'spender',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'addedValue',
        type: 'uint256',
      },
    ],
    name: 'increaseAllowance',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  };

  // eslint-disable-next-line
  const AllowanceMethod = connex.thor.account(vip180address).method(ABI);
  // bidMethod
  //   .value(amountEVM)
  // eslint-disable-next-line
  const allowanceClause = AllowanceMethod.asClause(addressContract, amountEVM);

  const purchaseABI = {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      },
      {
        "internalType": "string",
        "name": "paymentType",
        "type": "string"
      }
    ],
    "name": "buy",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function",
    "payable": true
  };
  // eslint-disable-next-line
  const bidMethod = connex.thor.account(addressContract).method(purchaseABI);
  // eslint-disable-next-line
  const transferClause = bidMethod.asClause(_tokenId, paymentType);
  // eslint-disable-next-line
  // eslint-disable-next-line
  const result = await connexTransaction.feeDelegationSignTx(
    connex,
    service,
    payer,
    signerAddress,
    [allowanceClause, transferClause],
    comment
  );
  return result;
}

export {
  sign,
  mintToken,
  buyTokenId,
  updateTokenIdPrice,
  updateListingStatus,
  setApprovalForAll,
  safeTransferFromWithoutFee,
  safeTransferFromWithFee,
  getMyCollection,
  getMyCreations,
  getOnSale,
  registerAccount,
  getAccountInfo,
  woviesCountOfCollection,
  listedStatus,
  getTokenIdPrice,
  getProfileId,
  getCollectionCountOfCreator,
  getWoviesCountOfCollection,
  claimedStatus,
  claimVehashes,
  buyVehashes,
  listedStatusTokenId,
  batchClaimedStatus,
  batchCheckIsOnAuction,
  purchaseWithWOV
};
