import TokenIdListStatus from '@/types/TokenIdListStatus';

async function isOnAuction(addressContract: string, _tokenId: number): Promise<boolean> {
  // TODO
  // Topic definition for the query, query is to get the latest TokenIdListStatus for a specific tokenId, limit = 1, topic0 & 1 to be set
  // tokenId need to be converted from dec to hex to match blockchain log
  // need to see if in dev or prod to change the url
  const bestBlocksURL = 'https://sync-mainnet.veblocks.net/blocks/best';

  const blockNumber = await window.fetch(bestBlocksURL).then(response => response.json());

  const eventLogsURL = 'https://sync-mainnet.veblocks.net/logs/event';
  const payload = {
    range: {
      unit: 'block',
      from: 10441250,
      to: blockNumber.number,
    },
    options: {
      offset: 0,
      limit: 40000,
    },
    criteriaSet: [
      {
        address: addressContract,
        topic0: '0xe1d43e1424478ed33a2b3b2edcf854f5f1164021f8f8eb6c55bb93521300e683',
      },
    ],
    order: 'asc',
  };

  const eventLogsParams = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };

  // TODO
  // need to type the response from the event, today the event is coming from the blockchain log
  // in the future might come from a central backend/middle
  const eventLogsResponse = (await window.fetch(eventLogsURL, eventLogsParams).then(response => response.json())) as {
    data: string;
  }[];

  return eventLogsResponse['data'][0];
}

async function priceUpdate(addressContract: string, _tokenId: number): Promise<number> {
  // TODO
  // Topic definition for the query
  // need to see if in dev or prod to change the url
  const bestBlocksURL = 'https://sync-mainnet.veblocks.net/blocks/best';

  const blockNumber = await window.fetch(bestBlocksURL).then(response => response.json());

  const eventLogsURL = 'https://sync-mainnet.veblocks.net/logs/event';
  const payload = {
    range: {
      unit: 'block',
      from: 10441250,
      to: blockNumber.number,
    },
    options: {
      offset: 0,
      limit: 100000,
    },
    criteriaSet: [
      {
        address: addressContract,
        topic0: '0xf76d116111cfac5a1ba6ca8254df7583c455556f8ec59dda1737ed61697f9c5b',
      },
    ],
    order: 'desc',
  };

  const eventLogsParams = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };

  // TODO
  // need to type the response from the event, today the event is coming from the blockchain log
  // in the future might come from a central backend/middle
  const eventLogsResponse = (await window.fetch(eventLogsURL, eventLogsParams).then(response => response.json())) as {
    data: string;
  }[];

  return eventLogsResponse['data'][0];
}

async function getTokenIdListStatus(addressContract: string): Promise<any> {
  // TODO
  // Topic definition for the query
  // need to see if in dev or prod to change the url
  const bestBlocksURL = 'https://sync-mainnet.veblocks.net/blocks/best';
  // const bestBlocksURL = 'https://sync-testnet.veblocks.net/blocks/best';

  const blockNumber = await window.fetch(bestBlocksURL).then(response => response.json());

  const eventLogsURL = 'https://sync-mainnet.veblocks.net/logs/event';
  // const eventLogsURL = 'https://sync-testnet.veblocks.net/logs/event';
  const payload = {
    range: {
      unit: 'block',
      from: 10441250,
      to: blockNumber.number,
    },
    options: {
      offset: 0,
      limit: 40000,
    },
    criteriaSet: [
      {
        address: addressContract,
        topic0: '0xf76d116111cfac5a1ba6ca8254df7583c455556f8ec59dda1737ed61697f9c5b',
      },
    ],
    order: 'asc',
  };

  const eventLogsParams = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };

  const eventLogsResponse = (await window.fetch(eventLogsURL, eventLogsParams).then(response => response.json())) as {
    data: string;
  }[];
  const tokenIdListStatus = eventLogsResponse
    .map((eventLog): TokenIdListStatus | undefined => {
      const matched = eventLog['data'].slice(2).match(/.{1,64}/g);
      
      if (!matched) {
        return undefined;
      }
      let isListed: boolean;
      if (parseInt(matched[0], 16) === 0) {
        isListed = false;
      } else {
        isListed = true;
      }
      let payment = '';

      if(matched[4] === '5645540000000000000000000000000000000000000000000000000000000000'){
        payment = 'vet'
      }else{
        payment = 'wov'
      }
      const tokenIdListStatus: TokenIdListStatus = {
        owner: '0x' + eventLog['topics'][1].slice(2).slice(24),
        tokenId: parseInt(eventLog['topics'][2], 16),
        isListed: isListed,
        price: parseInt(matched[1], 16),
        payment: payment,
        blockTimestamp: eventLog['meta'].blockTimestamp,
      };

      return tokenIdListStatus;
    })
    .filter(tokenIdListStatus => {
      // remove all undefined values
      return tokenIdListStatus !== undefined;
    });

  if (tokenIdListStatus.length > 0) {
    // TODO
    // need to forEach and only keep the latest event for each unique tokenId
    // tokenIdListStatus.forEach(element => {

    // });

    const tokenIds = new Set(tokenIdListStatus.map(x => x?.tokenId));

    const element: any[] = groupBy(tokenIdListStatus, 'tokenId');

    const pivotArray: TokenIdListStatus[] = [];

    tokenIds.forEach((tokenId) => {
      const el = element[tokenId ?? ''];
      pivotArray.push(
        el.reduce(function(prev, current) {
          if (+current.blockTimestamp > +prev.blockTimestamp) {
            return current;
          } else {
            return prev;
          }
        })
      );
    });
    
    const pivotArrayFiltered = [...pivotArray.filter(x => x.isListed === true)]
    return pivotArrayFiltered.sort((a, b) => (a.price > b.price ? 1 : -1));
  }
  // return tokenIdListStatus;
}

function groupBy(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}



export {
  isOnAuction,
  priceUpdate,
  getTokenIdListStatus
};
