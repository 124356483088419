









































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { bus } from '@/main';
import * as utils from '@/operations/utils.js';

import * as common from '@/operations/common';
import * as blockchainMethod from '@/operations/blockchainMethod';
import AccountSign from '@/types/AccountSign';

import BaseModal from '../BaseModal.vue';

@Component({
  components: {},
})
export default class ModalAuctionCreation extends Vue {
  @Prop() modal_identifier;
  @Prop() formTokenId;
  @Prop() smartcontractAddress;
  @Prop() item;

  $refs!: {
    baseModal: BaseModal;
  };

  public isFiltering = 0;

  public formPaymentType = 'WoV';
  public formPrice = '';

  get accountSignCompute(): AccountSign {
    return this.$store.getters.account;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get approvedSignCompute(): any {
    switch (this.smartcontractAddress) {
      case this.env.WOV_COMMUNITY_CARD_NFT:
        return this.$store.getters.account.approvedCommunityAuction;
      case this.env.SPECIAL_EDITION_CARD_NFT:
        return this.$store.getters.account.approvedSpecialEditionDutch;
      default:
        return false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get env() {
    return this.$store.getters.env;
  }

  get tokenId(): number {
    return this.$props.formTokenId;
  }

  data(): { [key: string]: string } {
    return {
      modalKey: `ModalAuctionCreationFor${this.item.tokenId}`,
    };
  }

  displayButton() {
    return this.accountSignCompute.signedIn && !this.isOnAuction(this.tokenId, this.smartcontractAddress);
  }

  isOnAuction(tokenId: number, smartcontractAddress: string): boolean {
    if (tokenId != null) {
      // console.log(common?.isOnAuction(this.$store.state, tokenId, smartcontractAddress)[0].isOnAuction)
      return common?.isOnAuction(this.$store.state, tokenId, smartcontractAddress)[0].isOnAuction;
    } else {
      return false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async addApproval(event, smartcontractAddress: string): Promise<void> {
    event.preventDefault();

    let result = await blockchainMethod.setApprovalForAll(
      this.$connex,
      this.$VIP191SERVICE,
      this.$VIP191PAYER,
      'set approval for all',
      smartcontractAddress,
      this.accountSignCompute.address,
      this.$ENV.AUCTION_COMMUNITY_NFT
    );
    bus.$emit('approvalEvent', result.txid);
    bus.$emit('showToast', true);

    event.preventDefault();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async createAuction(tokenId: number): Promise<void> {
    //  create auction could be on detail page too, should be a component
    alert('You have select ' +  this.formPaymentType + ' as payment type');
    let price = utils.humanToEVM(this.formPrice);
    price = '0x' + parseInt(price).toString(16);
    if (
      parseInt(this.formPrice) > 0 
    ) {
      blockchainMethod
        .updateTokenIdPrice(
          this.$connex,
          this.$VIP191SERVICE,
          this.$VIP191PAYER,
          'Create fixed price auction',
          this.env.AUCTION_COMMUNITY_NFT,
          this.accountSignCompute.address,
          tokenId,
          price,
          this.formPaymentType
        )
        .then((result) => {
          this.$refs.baseModal.hideModal();
          bus.$emit('auctionCreateEvent', result.txid);
          bus.$emit('showToast', true);
        });
    } else {
      alert(
        "Wrong value. Can't submit the auction like this. Make sure that Price > 0."
      );
    }
  }
  public async cancelAuction(tokenId: number): Promise<void> {
    const result = await blockchainMethod.updateListingStatus(
      this.$connex,
      this.$VIP191SERVICE,
      this.$VIP191PAYER,
      'Cancel Auction',
      this.$ENV.AUCTION_COMMUNITY_NFT,
      this.accountSignCompute.address,
      tokenId
    );
    bus.$emit('cancelAuctionEvent', result.txid);
    bus.$emit('showToast', true);
  }

}
