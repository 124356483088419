













































import { Component, Prop, Vue } from 'vue-property-decorator';

import BaseArtistAccount from './BaseArtistAccount.vue';
import BaseCollectionInformation from './BaseCollectionInformation.vue';
import BaseTokenName from './BaseTokenName.vue';
import ModalAuctionBuy from './util/ModalAuctionBuy.vue';
import ModalTokenPreview from './previewModal/ModalTokenPreview.vue';
import * as common from '@/operations/common';
import VeHashesMetadata from '@/types/VeHashesMetadata';

@Component({
  components: { BaseArtistAccount, BaseCollectionInformation, BaseTokenName, ModalAuctionBuy, ModalTokenPreview },
})
export default class BaseTokenBox extends Vue {
  @Prop() item;
  @Prop() showOnSale;

  private tokenProperty;
  public itemData?: any = {};
  public propertiesLoaded = false;

  private claimed = false;

  private metadata: VeHashesMetadata = {} as any;

  private async getTokenProperties(): Promise<void> {
    this.tokenProperty = await common.getTokenId(
      this.$connex,
      this.item.tokenId,
      this.$store.state.env.WOV_COMMUNITY_CARD_NFT
    );

    this.itemData = {
      ...this.item,
      ...this.tokenProperty,
    };

    this.propertiesLoaded = true;
  }

  public async mounted(): Promise<void> {
    this.itemData = this.item;
    this.getTokenProperties();
    this.metadata = await window
      .fetch(this.item.ipfsHash, { mode: 'cors' })
      .then((response) => response.json())
      .catch((err) => {
        console.error(err);
        return err;
      });

    const myHeaders = new Headers();
    myHeaders.append('xc-token', 'NYZ1NrdOlAd7g7yciYDebmpIu2tUoLOI56DvDzKG');
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };
    fetch(
      'https://wov-nft-backend-v1.herokuapp.com/nc/artistlist_kgul/api/v1/claimedVehashes/findOne?where=(vehashesId,eq,' + this.item.tokenId + ')',
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        this.claimed = true;
      })
      .catch(() => this.claimed = false);

  }
}
