



































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { bus } from '../../main';

import AccountSign from '@/types/AccountSign';

import * as blockchainMethod from '@/operations/blockchainMethod';
import * as readVIP181Core from '@/operations/readVIP181Core';

import BaseAccountPreview from './BaseAccountPreview.vue';
import BaseModalPurchasePreview from './BaseModalPurchasePreview.vue';
import ModalAuctionCreation from '../util/ModalAuctionCreation.vue';
import ModalSafeTransfer from '../util/ModalSafeTransfer.vue';

@Component({
  components: { BaseAccountPreview, BaseModalPurchasePreview, ModalAuctionCreation, ModalSafeTransfer },
})
export default class BaseEditionSelectionPreview extends Vue {
  @Prop() item;

  private owner = '';
  private isOnAuction = false;
  private price = 0;

  get accountSignCompute(): AccountSign {
    return this.$store.getters.account;
  }

  get smartcontractAddress(): string {
    return this.$ENV.WOV_COMMUNITY_CARD_NFT;
  }

  // need to add dynamic check
  get payment(): string {
    return this.item.payment;
  }
  isNotTokenOwner(): boolean {
    return this.accountSignCompute.address != this.owner;
    // return true;
  }
  public async refreshAuctionInfo() {
    this.getOwner();
    // this.isOnAuction = await blockchainMethod.listedStatus(
    //   this.$connex,
    //   this.$ENV.AUCTION_COMMUNITY_NFT,
    //   this.item.tokenId
    // );
    
    this.isOnAuction = false;
    if (this.isOnAuction) {
      this.price = await blockchainMethod.getTokenIdPrice(
        this.$connex,
        this.$ENV.AUCTION_COMMUNITY_NFT,
        this.item.tokenId
      );
    }
  }

  public async getOwner() {
    this.owner = await readVIP181Core.getOwnerOf(this.$connex, this.$ENV.WOV_COMMUNITY_CARD_NFT, this.item.tokenId);
    console.log(this.owner);
  }
  public async buyMe(event: Event): Promise<void> {
    event.preventDefault();
    // this.$refs.imageModal.hideModal();
    if (this.payment.toLowerCase() === 'vet') {
      blockchainMethod
        .buyTokenId(
          this.$connex,
          this.$VIP191SERVICE,
          this.$VIP191PAYER,
          'Auction bid',
          this.$ENV.AUCTION_COMMUNITY_NFT,
          this.accountSignCompute.address ?? '',
          this.item.tokenId,
          this.price.toString()
        )
        .then((result) => {
          bus.$emit('bidAuctionEvent', result.txid);
          bus.$emit('showToast', true);
        });
    } else if (this.payment.toLowerCase() === 'wov') {
      blockchainMethod
        .purchaseWithWOV(
          this.$connex,
          this.$VIP191SERVICE,
          this.$VIP191PAYER,
          'Auction bid',
          this.$ENV.AUCTION_COMMUNITY_NFT,
          this.accountSignCompute.address ?? '',
          this.item.tokenId,
          this.price.toString(),
          this.$ENV.WOV_VIP180,
          'WoV'
        )
        .then((result) => {
          bus.$emit('bidAuctionEvent', result.txid);
          bus.$emit('showToast', true);
        });
    }
  }

  @Watch('item', { immediate: true }) async onItemChanged(): Promise<void> {
    this.getOwner();
    console.log(this.item)
    this.isOnAuction = await blockchainMethod.listedStatus(
      this.$connex,
      this.$ENV.AUCTION_COMMUNITY_NFT,
      this.item.tokenId
    );
    if (this.isOnAuction) {
      this.price = await blockchainMethod.getTokenIdPrice(
        this.$connex,
        this.$ENV.AUCTION_COMMUNITY_NFT,
        this.item.tokenId
      );
    }
  }
}
