import TokenProperty from '@/types/TokenProperty';
import * as bs58 from 'bs58';

/**
 * Get token supply of from a contract.
 * @param {String} addressContract 0x started address. NFT Smart-Contract address
 *
 */
async function getTotalSupply(connex: Connex, addressContract: string): Promise<number> {
  const ABI = {
    "inputs": [],
    "name": "getTokenToPreSale",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  };
  // eslint-disable-next-line
  const method = connex.thor.account(addressContract).method(ABI);
  const transfer = await method.call();
  return transfer.decoded[0];
}

async function tokenURI(connex: Connex, addressContract: string, tokenId: number): Promise<string> {
  const ABI =  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "tokenURI",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  };
  // eslint-disable-next-line
  const method = connex.thor.account(addressContract).method(ABI);
  const transfer = await method.call(tokenId);
  return transfer.decoded[0];
}

/**
 * Get balance of token from an address
 * @param {String} addressContract 0x started address. NFT Smart-Contract address
 *
 */
async function getBalanceOf(connex: Connex, addressContract: string, addressHolder: string): Promise<number> {
  
  const ABI = {
    inputs: [
      {
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
    ],
    name: 'balanceOf',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  };
  // eslint-disable-next-line
  const method = connex.thor.account(addressContract).method(ABI);
  const transfer = await method.call(addressHolder);
  return parseInt(transfer.decoded[0]);
}

/**
 * Get the owner address of a tokenID
 * @param {String} addressContract 0x started address. NFT Smart-Contract address
 *
 */
async function getOwnerOf(connex: Connex, addressContract: string, tokenID: number): Promise<string> {
  const ABI = {
    inputs: [
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'ownerOf',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
    constant: true,
  };
  // eslint-disable-next-line
  const method = connex.thor.account(addressContract).method(ABI);
  const transfer = await method.call(tokenID);
  return transfer.decoded[0];
}

async function getTokenOfOwnerByIndex(
  connex: Connex,
  addressContract: string,
  addressHolder: string,
  index: number
): Promise<number> {
  const ABI = {
    inputs: [
      {
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'index',
        type: 'uint256',
      },
    ],
    name: 'tokenOfOwnerByIndex',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
    constant: true,
  };
  // eslint-disable-next-line
  const method = connex.thor.account(addressContract).method(ABI);
  const transfer = await method.call(addressHolder, index);
  
  return parseInt(transfer.decoded[0]);
}

async function isApprovedForAll(
  connex: Connex,
  addressContract: string,
  owner: string,
  operator: string
): Promise<boolean> {
  const ABI = {
    inputs: [
      {
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'operator',
        type: 'address',
      },
    ],
    name: 'isApprovedForAll',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
    constant: true,
  };
  // eslint-disable-next-line
  const method = connex.thor.account(addressContract).method(ABI);
  const transfer = await method.call(owner, operator);
  return transfer.decoded[0];
}

async function getTokenProperties(connex: Connex, addressContract: string, tokenId: number) {
  const ABI = {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "getTokenProperties",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      },
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      },
      {
        "internalType": "uint64",
        "name": "",
        "type": "uint64"
      },
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  };
  // eslint-disable-next-line
  const method = connex.thor.account(addressContract).method(ABI);
  const transfer = await method.call(tokenId);
  return transfer.decoded;
}

async function getTokenCuratedProperties(connex: Connex, addressContract: string, tokenId: number) {
  const ABI = {
    inputs: [
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'getTokenCuratedProperties',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'uint64',
        name: '',
        type: 'uint64',
      },
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
    constant: true,
  };
  // eslint-disable-next-line
  const method = connex.thor.account(addressContract).method(ABI);
  const transfer = await method.call(tokenId);
  return transfer.decoded;
}
export {
  getTotalSupply,
  tokenURI,
  getBalanceOf,
  getOwnerOf,
  getTokenOfOwnerByIndex,
  isApprovedForAll,
  getTokenProperties,
  getTokenCuratedProperties,
};
