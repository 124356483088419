








import { Component, Vue } from 'vue-property-decorator';

import { bus } from '../../main';

import BaseModal from '../BaseModal.vue';

@Component({
  components: {},
})
export default class ModalEvent extends Vue {
  $refs!: {
    baseModal: BaseModal;
  };

  public modalNotificationTitle = '';
  public modalNotificationBody = '';

  public count = 0;
  public stop = false;

  get isSignedIn(): boolean {
    return this.$store.getters.account.signedIn;
  }

  public async eventService(eventType: string, txid: string): Promise<void> {
    let tick = this.$connex.thor.ticker();
    this.count++;
    if (this.count === 3) {
      this.count = 0;
      this.$connex.thor
        .transaction(txid)
        .getReceipt()
        .then((txReceipt) => {
          if (txReceipt != null) {
            if (!txReceipt?.reverted) {
              switch (eventType) {
                case 'mintEvent':
                  this.modalNotificationTitle = 'Congratulations!';
                  this.modalNotificationBody = `You've successfully claimed your VeHashes. You can go to your profile to see them.`;
                  this.$store.dispatch('getAccountwovCommunityBalanceOf');
                  this.$store.dispatch('refreshAccountSign');
                  break;
                case 'nameEvent':
                  this.modalNotificationTitle = 'Congratulations!';
                  this.modalNotificationBody = 'You just renamed your card.';
                  bus.$emit('refreshName');
                  break;
                case 'bidAuctionEvent':
                  this.modalNotificationTitle = 'Success';
                  this.modalNotificationBody =
                    'Congratulation you bought a new card. Go to Manage Assets to view it. The transaction is successfull.';
                  this.$store.dispatch('refreshAccountSign');
                  this.$store.dispatch('getAuctionList');
                  bus.$emit('refreshOwner');
                  break;
                case 'approvalEvent':
                  this.modalNotificationTitle = 'Success';
                  this.modalNotificationBody = 'Approve Confirmed, please click on CONFIRM SALE.';
                  this.$store.dispatch('refreshAccountApproval');
                  bus.$emit('refreshApproval');
                  break;
                case 'auctionCreateEvent':
                  this.modalNotificationTitle = 'Congratulations!';
                  this.modalNotificationBody = 'Your auction is created.';
                  this.$store.dispatch('getAuctionList');
                  this.$store.dispatch('refreshAccountSign');
                  break;
                case 'cancelAuctionEvent':
                  this.modalNotificationTitle = 'Success';
                  this.modalNotificationBody = 'You have successfully cancelled your auction.';
                  this.$store.dispatch('getAuctionList');
                  this.$store.dispatch('refreshAccountSign');
                  break;
                case 'claimEvent':
                  this.modalNotificationTitle = 'Success';
                  this.modalNotificationBody = 'You have successfully claimed your WOV token.';
                  this.$store.dispatch('refreshAccountSign');
                  bus.$emit('refreshClaiming');
                  break;
                case 'transferEvent':
                  this.modalNotificationTitle = 'Success';
                  this.modalNotificationBody = 'You have successfully transfer your NFT.';
                  this.$store.dispatch('refreshAccountSign');
                  break;
                case 'voteEvent':
                  this.modalNotificationTitle = 'Success';
                  this.modalNotificationBody = 'You have successfully participated to the voting pool.';
                  this.$store.dispatch('refreshAccountSign');
                  break;
                case 'transferResult':
                  this.modalNotificationTitle = 'Success';
                  this.modalNotificationBody = 'You have successfully transfered your nft.';
                  this.$store.dispatch('refreshAccountSign');
                  bus.$emit('refresTokenTransfer');
                  break;
                default:
                  break;
              }
              this.$refs.baseModal.showModal();
              this.stop = true;
            } else {
              this.modalNotificationTitle = 'Error';
              this.modalNotificationBody = 'The transaction is reverted. Please try again.';
              this.$refs.baseModal.showModal();
            }
          }
        });
    } else {
      tick.next().then(() => {
        if (!this.stop) {
          this.eventService(eventType, txid);
        }
      });
    }
  }

  public showToast(): void {
    this.$bvToast.toast('Processing in the background....', {
      title: 'Processing',
      solid: false,
      autoHideDelay: 9000,
      variant: 'secondary',
    });
  }

  public async created(): Promise<void> {
    bus.$on('mintEvent', (data) => {
      this.stop = false;
      this.count = 0;
      this.eventService('mintEvent', data);
    });
    bus.$on('nameEvent', (data) => {
      this.stop = false;
      this.count = 0;
      this.eventService('nameEvent', data);
    });
    bus.$on('bidAuctionEvent', (data) => {
      this.stop = false;
      this.count = 0;
      this.eventService('bidAuctionEvent', data);
    });
    bus.$on('approvalEvent', (data) => {
      this.stop = false;
      this.count = 0;
      this.eventService('approvalEvent', data);
    });
    bus.$on('auctionCreateEvent', (data) => {
      this.stop = false;
      this.count = 0;
      this.eventService('auctionCreateEvent', data);
    });
    bus.$on('cancelAuctionEvent', (data) => {
      this.stop = false;
      this.count = 0;
      this.eventService('cancelAuctionEvent', data);
    });
    bus.$on('claimEvent', (data) => {
      this.stop = false;
      this.count = 0;
      this.eventService('claimEvent', data);
    });
    bus.$on('transferEvent', (data) => {
      this.stop = false;
      this.count = 0;
      this.eventService('transferEvent', data);
    });
    bus.$on('voteEvent', (data) => {
      this.stop = false;
      this.count = 0;
      this.eventService('voteEvent', data);
    });

    bus.$on('transferResult', (data) => {
      this.stop = false;
      this.count = 0;
      this.eventService('transferResult', data);
    });

    bus.$on('showToast', (data) => {
      if (data) {
        this.showToast();
      }
    });
  }
}
