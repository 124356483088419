


























































import { Component, Vue, Prop } from 'vue-property-decorator';
import { bus } from '../../main';

import * as common from '../../operations/common';
import * as blockchainMethod from '../../operations/blockchainMethod';
import AccountSign from '@/types/AccountSign';

import BaseModal from '../BaseModal.vue';

@Component({
  components: {},
})
export default class ModalAuctionBuy extends Vue {
  @Prop() smartcontractAddress;
  @Prop() item;
  @Prop() auction;

  $refs!: {
    baseModal: BaseModal;
  };

  public isFiltering = 0;

  get tokenId(): number {
    return this.auction.tokenId;
  }

  get payment(): string {
    return this.auction.payment;
  }

  get accountSignCompute(): AccountSign {
    return this.$store.getters.account;
  }

  get price(): number {
    return this.auction.price;
  }

  data(): { [key: string]: string } {
    return {
      modalKey: `ModalAuctionBuyFor${this.item.tokenId}`,
    };
  }

  displayButton(): boolean | undefined {
    return true;
    // return this.accountSignCompute?.signedIn && this.isOnAuction() && this.isNotTokenOwner();
  }

  formatPrice(price: number): string {
    return Intl.NumberFormat('us-US', {
      maximumFractionDigits: 2,
    }).format(price / 1000000000000000000);
  }

  isOnAuction(): boolean {
    return common.isOnAuction(this.$store.state, this.item.tokenId, this.smartcontractAddress)[0].isOnAuction;
  }

  isNotTokenOwner(): boolean {
    return this.accountSignCompute.address != this.item.tokenOwner;
  }

  public async buyMe(event: Event): Promise<void> {
    event.preventDefault();

    this.$refs.baseModal.hideModal();

    // if (this.payment === 'vet') {
    //   blockchainMethod
    //     .buyTokenId(
    //       this.$connex,
    //       this.$VIP191SERVICE,
    //       this.$VIP191PAYER,
    //       'Auction bid',
    //       this.$ENV.AUCTION_COMMUNITY_NFT,
    //       this.accountSignCompute.address ?? '',
    //       this.tokenId,
    //       this.price.toString()
    //     )
    //     .then((result) => {
    //       bus.$emit('bidAuctionEvent', result.txid);
    //       bus.$emit('showToast', true);
    //     });
    // }
  }
}
