
































import { Component, Vue, Prop } from 'vue-property-decorator';

import AccountSign from '@/types/AccountSign';
import ImageModal from '../ImageModal.vue';
import { bus } from '../../main';

import * as common from '@/operations/common';
import * as blockchainMethod from '@/operations/blockchainMethod';

import BaseAccountPreview from './BaseAccountPreview.vue';
import BaseCollectionPreview from './BaseCollectionPreview.vue';
import BaseTokenNamePreview from './BaseTokenNamePreview.vue';
import BaseTokenDescriptionPreview from './BaseTokenDescriptionPreview.vue';
import BaseModalPurchasePreview from './BaseModalPurchasePreview.vue';
import BaseEditionSelectionPreview from './BaseEditionSelectionPreview.vue';

@Component({
  components: {
    ImageModal,
    BaseAccountPreview,
    BaseCollectionPreview,
    BaseTokenNamePreview,
    BaseTokenDescriptionPreview,
    BaseModalPurchasePreview,
    BaseEditionSelectionPreview,
  },
})
export default class ModalTokenPreview extends Vue {
  @Prop() item;
  @Prop() auction;
  @Prop(String) fileType;
  @Prop() tokenMetadata;
  @Prop() metadata;

  $refs!: {
    imageModal: ImageModal;
  };

  public isFiltering = 0;

  get tokenId(): number {
    return this.auction.tokenId;
  }

  get payment(): string {
    return this.auction.payment;
  }

  get accountSignCompute(): AccountSign {
    return this.$store.getters.account;
  }

  get price(): number {
    return this.auction.price;
  }

  data(): { [key: string]: string } {
    return {
      modalKey: `ModalTokenPreviewFor${this.item.tokenId}`,
    };
  }

  formatPrice(price: number): string {
    return Intl.NumberFormat('us-US', {
      maximumFractionDigits: 2,
    }).format(price / 1000000000000000000);
  }

  public onImageLoaded(event): void {
    let img = document.getElementById(event.target.id) as HTMLImageElement;
    if (event.target.naturalHeight > event.target.naturalWidth && img != undefined) {
      img.classList.add('portrait');
    } else {
      img.classList.add('landscape');
    }
  }

}
