import Connex from '@vechain/connex';
import BigNumber from 'bignumber.js';
import * as readVIP181Core from '../operations/readVIP181Core';
import * as vip180 from '../operations/vip180';
import * as marketplaceCore from '../operations/marketplaceCore';

import * as common from '../operations/common';
import State from '@/types/State';
import { Store } from 'vuex';
import Marketplace from '@/types/Marketplace';
import VekingProperty from '@/types/VekingProperty';
import TokenProperty from '@/types/TokenProperty';
import Auction from '@/types/Auction';

const connex = new Connex({
  node: process.env.VUE_APP_NODE,
  network: process.env.VUE_APP_NETWORK,
});

export default {
  async refreshAccountSign(store: Store<State>): Promise<void> {
    const address = store.state.account.address;

    if (!address || address === '') {
      console.warn('Cannot refresh Account Sign as the address is null or empty');
      return;
    }

    const info = await connex.thor.account(store.state.account.address).get();
    const temp = new BigNumber(info.balance);
    store.state.account.vetBalance = parseInt(temp.div(new BigNumber('1e+' + 18)).toString());
    store.state.account.wovBalance = await vip180.balanceOf(
      connex,
      store.state.env.WOV_VIP180,
      store.state.account.address
    );
    // console.log(store.state.env.WOV_COMMUNITY_CARD_NFT)
    // console.log(store.state.account.address)
    store.state.account.wovCommunityBalance = await readVIP181Core.getBalanceOf(
      connex,
      store.state.env.WOV_COMMUNITY_CARD_NFT,
      store.state.account.address
    );
    
    store.state.account.approvedCommunityAuction = await readVIP181Core.isApprovedForAll(
      connex,
      store.state.env.WOV_COMMUNITY_CARD_NFT,
      store.state.account.address,
      store.state.env.AUCTION_COMMUNITY_NFT
    );
    localStorage.wallet = JSON.stringify(store.state.account);

    store.state.account.signedIn = true;

    store.commit('setAccountSign', store.state.account);
  },

  async refreshAccountWoV(store: Store<State>): Promise<void> {
    const balance = await vip180.balanceOf(connex, store.state.env.WOV_VIP180, store.state.account.address);
    store.commit('setWoVBalance', balance);
  },

  async refreshAccountCommunityNFT(store: Store<State>): Promise<void> {
    const res = await common.getListOfTokenIdForAddress(
      connex,
      store.state,
      store.state.account.wovCommunityBalance,
      store.state.account.address,
      store.state.env.WOV_COMMUNITY_CARD_NFT
    );
    store.commit('setAccountCommunityNFT', res);
  },

  async refreshAccountApproval(store: Store<State>): Promise<void> {
    const approvedCommunityAuction = await readVIP181Core.isApprovedForAll(
      connex,
      store.state.env.WOV_COMMUNITY_CARD_NFT,
      store.state.account.address,
      store.state.env.AUCTION_COMMUNITY_NFT
    );
    store.commit('updateApprovalStatus', {
      communityAuction: approvedCommunityAuction,
    });
  },

  async getAuctionList(store: Store<State>): Promise<void> {
    const array = store.state.marketplace;
    if (store.state.marketplace?.length > 0) {
      store.state.marketplace.length = 0;
      array.length = 0;
    }

    const marketplace = await marketplaceCore.getTokenIdListStatus(store.state.env.AUCTION_COMMUNITY_NFT);
   
    if (marketplace && marketplace.length > 0) {
      for (let index = 0; index < marketplace.length; index++) {
        const element = marketplace[index];
        const tokenURItype = 'worldofv'
        

        // need to add payment, VET or WoV to be configured in smartContract
        // ipfsHash need to find how to get the hash in a smart way. (from the event directly ?)
        if (tokenURItype != undefined) {
          if (tokenURItype.includes('worldofv')) {
            const tokenProperty: TokenProperty = await common.getTokenId(
              connex,
              element.tokenId,
              store.state.env.WOV_COMMUNITY_CARD_NFT
            );

            const auction: Auction = {
              tokenId: element.tokenId,
              tokenName:tokenProperty.tokenName,
              tokenCreator:tokenProperty.tokenCreator,
              collectionName:tokenProperty.collectionName,
              description:tokenProperty.description,
              creatorCommission:tokenProperty.creatorCommission,
              ipfsHash: tokenProperty.ipfsHash,
              fileHash: tokenProperty.fileHash,
              fileType: tokenProperty.fileType,
              isOnAuction: element.isListed,
              owner: element.owner,
              startedAt: element.timestamp,
              price: element.price,
              payment: element.payment,
              smartcontract: store.state.env.WOV_COMMUNITY_CARD_NFT,
            };
            array.push(auction);
          }
        }
      }
    }

    store.commit('setAuctionList', array);
  },

  async getAccountwovCommunityBalanceOf(store: Store<State>): Promise<void> {
    const wovCommunityBalance = await readVIP181Core.getBalanceOf(
      connex,
      store.state.env.WOV_COMMUNITY_CARD_NFT,
      store.state.account.address
    );
    store.commit('setAccountwovCommunityBalanceOf', wovCommunityBalance);
  },
};
