import Vue from 'vue';
import Vuex, { ActionTree } from 'vuex';


import getters from './getters';
import mutations from './mutations';
import actions from './actions';

import AccountSign from '@/types/AccountSign'
import Env from '@/types/Env'
import State from '@/types/State';

Vue.use(Vuex);

const accountSign: AccountSign = {
  address: '',
  vetBalance: 0,
  wovBalance: 0,
  wovCommunityBalance: 0,
  signedIn: false,
  approvedCommunityAuction: false,
};

const env: Env = {
  ENV: '',
  VTHO_CONTRACT: '',
  WOV_VIP180: '',
  WOV_COMMUNITY_CARD_NFT: '',
  COMMUNITY_NFT_METADATA: '',
  WOV_ACCOUNT_CONTRACT: '',
  GENESIS_TOKEN: '',
  SPECIAL_TOKEN: '',
  AUCTION_COMMUNITY_NFT: '',
  FEE_POOL_ADDRESS: '',
  VERSION: '',
  EXPLORER: '',
  NODE: '',
  NETWORK: '',
  GENESIS_AUCTION_CONTRACT: '',
  SPECIAL_AUCTION_CONTRACT: '',
};


const state: State = {
  env: env,
  account: accountSign,
  accountCommunityNFT: [],
  marketplace: [],
};

// TODO: Need to reorganize actions.ts in order to match ActionTree type!!!
const actionsVuex = actions as unknown as ActionTree<State, State>;

export const store = new Vuex.Store<State>({
  state: state,
  getters,
  mutations,
  actions: actionsVuex
});
