import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Claim.vue'),
  },
  {
    path: '/claim',
    name: 'Claim',
    component: () => import('../views/Claim.vue'),
  },{
    path: '/owner/:ownerAddress',
    name: 'Owner',
    component: () => import('../views/Owner.vue')
  },

  
];

const router = new VueRouter({
  routes,
});

export default router;
