
































































































import { Component, Vue } from 'vue-property-decorator';
import { bus } from '@/main';

import * as blockchainMethod from '@/operations/blockchainMethod';

import axios, { AxiosResponse } from 'axios';
import PinataResponse from 'src/types/PinataResponse';

import AccountSign from '@/types/AccountSign';

@Component({
  components: {},
})
export default class BaseCreateToken extends Vue {
  public isFiltering = 0;

  public formAccountName = '';
  public formAccountDescription = '';
  public formAccountTwitter = '';
  public formAccountInstagram = '';
  public formAccountFacebook = '';
  public formAccountDiscord = '';
  public filePreview?: string;
  public file!: File;
  public showPreview = false;
  public previewKey = 0;
  public uploadLabel = ' Choose File ';
  public maxSize = 2 * 1048576;

  get accountSignCompute(): AccountSign {
    return this.$store.getters.account;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get env() {
    return this.$store.getters.env;
  }

  public onFileChange(e: Event | undefined): void {
    if (e != undefined) {
      const target = e.target as HTMLInputElement;
      this.file = (target.files as FileList)[0];
      if (this.file.size >= this.maxSize) {
        alert('File is too big!');
      } else {
        this.filePreview = URL.createObjectURL(this.file);
        this.showPreview = true;
        this.uploadLabel = ' Change File ';
        this.previewKey++;
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async createAuction(tokenId: number, event, smartcontractAddress: string): Promise<void> {
    event.preventDefault();
  }

  public async ipfsUpload(event: Event): Promise<void> {
    event.preventDefault();

    if (this.file != undefined && this.file.size <= this.maxSize) {
      let data = this.file;
      if (data.size > 0) {
        const url = process.env.VUE_APP_IPFS_BASE_URL + '/pinning/pinFileToIPFS';
        let form = new FormData();
        form.append('file', data);

        //You'll need to make sure that the metadata is in the form of a JSON object that's been converted to a string
        //metadata is optional
        const pinataOptions = JSON.stringify({ cidVersion: 0 });
        form.append('pinataOptions', pinataOptions);
        const pinataMetadata = JSON.stringify({
          name: this.formAccountName,
        });
        form.append('pinataMetadata', pinataMetadata);
        let response = await axios.post<FormData, AxiosResponse<PinataResponse>>(url, form, {
          maxBodyLength: Infinity,
          headers: {
            'Content-Type': `multipart/form-data; boundary=${(form as any)._boundary}`,
            Authorization: `Bearer ${process.env.VUE_APP_IPFS_JWT}`,
          },
        });
        if (response.status == 200) {
          // account type to change from 3 to -> 0 = artist, 1 = collector, 2  = both
          const pinataContent = {
            accountName: this.formAccountName,
            accountDescription: this.formAccountDescription,
            accountWalletAddress: this.accountSignCompute.address,
            accountType: 2,
            accountTwitter: this.formAccountTwitter,
            accountInstagram: this.formAccountInstagram,
            accountFacebook: this.formAccountFacebook,
            accountDiscord: this.formAccountDiscord,
            fileHash: `${response.data.IpfsHash}`,
            fileType: data.type,
          };
          const payload = {
            pinataOptions: pinataOptions,
            pinataMetadata: pinataMetadata,
            pinataContent: pinataContent,
          };

          const jsonUrl = `${process.env.VUE_APP_IPFS_BASE_URL}/pinning/pinJSONToIPFS`;
          let jsonResponse = await axios.post<unknown, AxiosResponse<PinataResponse>>(jsonUrl, payload, {
            headers: {
              Authorization: `Bearer ${process.env.VUE_APP_IPFS_JWT}`,
            },
          });
          if (jsonResponse.data.IpfsHash && jsonResponse.data.IpfsHash.length > 0) {
            await this.registerAccount(jsonResponse.data.IpfsHash, pinataContent.fileHash, this.formAccountName, 2);
          }
        }
      }
    }
  }

  public async registerAccount(
    ipfsHash: string,
    fileHash: string,
    accountName: string,
    accountType: number
  ): Promise<void> {
    await blockchainMethod
      .registerAccount(
        this.$connex,
        this.$VIP191SERVICE,
        this.$VIP191PAYER,
        'Account creation transaction',
        this.$ENV.WOV_ACCOUNT_CONTRACT,
        this.accountSignCompute.address,
        ipfsHash,
        fileHash,
        accountName,
        accountType
      )
      .then((result) => {
        bus.$emit('mintEvent', result.txid);
        bus.$emit('showToast', true);
        this.$router.push({ path: `owner/${this.accountSignCompute.address}` });
      });
  }
}
