import BigNumber from 'bignumber.js';
import * as vip180 from '../operations/vip180';
import * as readVIP181Core from '../operations/readVIP181Core';

import AddressInfo from '@/types/AddressInfo';

import State from '@/types/State';
import Marketplace from '@/types/Marketplace';
import ConnexClass from '@vechain/connex';

import VekingProperty from '@/types/VekingProperty';
import TokenProperty from '@/types/TokenProperty';
import TokenPropertyCurated from '@/types/TokenPropertyCurated';
import IpfsMetadata from '@/types/IpfsMetadata';
import InitialTokenProperty from '@/types/InitialTokenProperty';

// TODO: This function cano return a boolean AND a MarketPlace. We need to split 2x functions based on what is needed.
// OR just return an empty list when it is not on auction. Then rename function in 'getAuctions'
function isOnAuction(
  state: State,
  tokenId: number,
  smartcontractAddress: string
): Marketplace[] | [{ isOnAuction: boolean; payment: string; price: number }] {
  const isAuction = state.marketplace.filter(
    item => item.tokenId === tokenId && item.smartcontract === smartcontractAddress
  );

  if (isAuction.length > 0) {
    // console.log(isAuction)
    return isAuction;
  } else {
    return [
      {
        isOnAuction: false,
        payment: '',
        price: 0,
      },
    ];
  }
}

async function getAddressInfo(connex: ConnexClass, state: State, address: string): Promise<AddressInfo> {
  const info = await connex.thor.account(address).get();
  const temp = new BigNumber(info.balance);

  const vetBalance = parseInt(temp.div(new BigNumber('1e+' + 18)).toString());
  const wovBalance = await vip180.balanceOf(connex, state.env.WOV_VIP180, address);

  // const wovCommunityBalance = 0;
  const wovCommunityBalance = await readVIP181Core.getBalanceOf(connex, state.env.WOV_COMMUNITY_CARD_NFT, address);
  return {
    address: address,
    vetBalance: vetBalance,
    wovBalance: wovBalance,
    wovBalanceOfOwner: wovCommunityBalance,
    wovBalanceOfCreator: 0,
  };
}

async function getListOfTokenIdForAddress(
  connex: ConnexClass,
  state: State,
  balance: number,
  address: string,
  smartcontractAddress: string
): Promise<InitialTokenProperty[]> {
  const tokenList: InitialTokenProperty[] = [];
  for (let index = 0; index < balance; index++) {
    const tokenId = await readVIP181Core.getTokenOfOwnerByIndex(connex, smartcontractAddress, address, index);
    const result = await readVIP181Core.getTokenProperties(connex, smartcontractAddress, tokenId);

    // would be better to type the result of getTokenProperties
    const tokenProperty = {
      tokenId: tokenId,
      tokenCreator: result[0],
      ipfsHash: result[1],
      fileHash: result[2],
      creatorCommission: result[3],
      curatedArt: result[4],
    };

    tokenList.push(tokenProperty);
  }
  return tokenList;
}

async function getTokenId(
  connex: ConnexClass,
  tokenId: number,
  smartcontractAddress: string
): Promise<TokenProperty | TokenPropertyCurated> {

  return {
    tokenId: tokenId,
    tokenName: 'VeHash',
    tokenCreator: '0xE52fc0e8538e4eCb8718Aa0dfEcb9b6D066746d6',
    ipfsHash: 'https://vehashes.s3.eu-central-1.amazonaws.com/metadata/' + tokenId + '.json',
    fileHash: 'https://vehashes.s3.eu-central-1.amazonaws.com/assets/' + tokenId + '.png',
    fileType: 'image/png',
    collectionName: 'VeHashes',
    description: 'VeHashes is a PFP collection of 10,000 unique NFTs featuring a fun, creative visual style.\nThe collection celebrates the VeFam community by giving life to our beloved V.',
    creatorCommission: 0,
    curatedArt: false,
  };
}

async function fetchIpfsMetadata(url: string): Promise<IpfsMetadata> {
  const destinationUrl = url;
  if ('https://worldofv-marketplace.mypinata.cloud/ipfs/' != destinationUrl) {
    const resp = await window
      .fetch(destinationUrl)
      .then(response => {
        return response.json();
      })
      .catch(response => {
        // console.log(response);
        return {};
      });
    return resp;
  } else {
    return {
      name: '',
      description: '',
      creator: '',
      data: '',
      tags: [],
      collectionName: '',
      category: '',
      fileHash: '',
      fileType: '',
    };
  }
}
async function fetchVekingIpfsMetadata(url: string): Promise<VekingProperty> {
  const destinationUrl = url;
  const resp = await window.fetch(destinationUrl).then(response => {
    return response.json();
  });
  return resp;
}

export {
  isOnAuction,
  getAddressInfo,
  getListOfTokenIdForAddress,
  getTokenId,
  fetchIpfsMetadata,
  fetchVekingIpfsMetadata,
};
