import Vue from 'vue';
import App from './App.vue';
import router from './router';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import Connex from '@vechain/connex';
import { store } from './store/store';
import Multiselect from 'vue-multiselect'

import VueVirtualScroller from 'vue-virtual-scroller';
import vmodal from 'vue-js-modal';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import Env from './types/Env';
import "@/assets/styles/index.scss";

declare module 'vue/types/vue' {
  interface Vue {
    $connex: Connex;
    $connexVersion: number;
    $VIP191SERVICE: string;
    $VIP191PAYER: string;
    $WEBSITE_URL: string;
    $ENV: Env;
  }
}

// Register all Base* components
const requireComponent = require.context(
  // The relative path of the components folder
  './components',
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /Base[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach(fileName => {
  // Get component config
  const componentConfig = requireComponent(fileName);

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Gets the file name regardless of folder depth
      fileName
        ?.split('/')
        ?.pop()
        ?.replace(/\.\w+$/, '')
    )
  );

  Vue.component('multiselect', Multiselect);

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  );
});

const connex = new Connex({
  node: process.env.VUE_APP_NODE,
  network: process.env.VUE_APP_NETWORK,
});

declare global {
  interface Window {
    connex;
  }
}

export const bus = new Vue();

// VTHO TOKEN SMART-CONTRACT ADDRESS
Vue.prototype.$VIP191SERVICE = process.env.VUE_APP_VIP191SERVICE;
Vue.prototype.$VIP191PAYER = process.env.VUE_APP_VIP191PAYER;
Vue.prototype.$WEBSITE_URL = process.env.VUE_APP_URL;
Vue.prototype.$connexVersion = 0;

const env: Env = {
  ENV: process.env.VUE_APP_ENV,
  VTHO_CONTRACT: process.env.VUE_APP_VTHO_CONTRACT,
  WOV_VIP180: process.env.VUE_APP_WOV_VIP180,
  WOV_COMMUNITY_CARD_NFT: process.env.VUE_APP_WOV_COMMUNITY_CARD_NFT,
  GENESIS_TOKEN: process.env.VUE_APP_GENESIS_TOKEN,
  SPECIAL_TOKEN: process.env.VUE_APP_SPECIAL_TOKEN,
  COMMUNITY_NFT_METADATA: process.env.VUE_APP_COMMUNITY_NFT_METADATA,
  WOV_ACCOUNT_CONTRACT: process.env.VUE_APP_WOV_ACCOUNT_CONTRACT,
  AUCTION_COMMUNITY_NFT: process.env.VUE_APP_AUCTION_COMMUNITY_NFT,
  FEE_POOL_ADDRESS: process.env.VUE_APP_FEE_POOL_ADDRESS,
  VERSION: process.env.VUE_APP_VERSION,
  EXPLORER: process.env.VUE_APP_EXPLORER,
  NODE: process.env.VUE_APP_NODE,
  NETWORK: process.env.VUE_APP_NETWORK,
  GENESIS_AUCTION_CONTRACT: process.env.VUE_APP_GENESIS_AUCTION_CONTRACT,
  SPECIAL_AUCTION_CONTRACT: process.env.VUE_APP_SPECIAL_AUCTION_CONTRACT,
};

Vue.prototype.$ENV = env;

Vue.prototype.$connex = connex;

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(VueVirtualScroller);
Vue.use(vmodal);

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app');
