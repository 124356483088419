


















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import * as blockchainMethod from '@/operations/blockchainMethod';

@Component({
  components: {},
})
export default class BaseTokenNamePreview extends Vue {
  @Prop() item;

}
