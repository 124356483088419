


























































import { Component, Prop, Vue } from 'vue-property-decorator';
import attributes_value from '@/assets/json/vehashes_total_attributes__mapped__fixed__with-none.json';

import VeHashesMetadata from '@/types/VeHashesMetadata';
@Component({
  components: {},
})
export default class BaseTokenDescriptionPreview extends Vue {
  @Prop() item;
  @Prop() metadata;

  // private metadata: VeHashesMetadata = {} as any;

  private attributesCount = [
    {
      attributesCount: 2,
      vehashes: 2,
    },
    {
      attributesCount: 3,
      vehashes: 28,
    },
    {
      attributesCount: 4,
      vehashes: 331,
    },
    {
      attributesCount: 5,
      vehashes: 1308,
    },
    {
      attributesCount: 6,
      vehashes: 2525,
    },
    {
      attributesCount: 7,
      vehashes: 2988,
    },
    {
      attributesCount: 8,
      vehashes: 1897,
    },
    {
      attributesCount: 9,
      vehashes: 627,
    },
    {
      attributesCount: 10,
      vehashes: 93,
    },
    {
      attributesCount: 11,
      vehashes: 3,
    },
  ];

  get formattedObject() {
    if (this.metadata?.traits) {
      const mappedTraits = Object.entries(this.metadata.traits).map(([k, v]) => ({
        label: k,
        trait: v,
        score: this.metadata.scores[k],
        count: attributes_value[k][v],
      }));

      return [
        {
          label: 'Attribute Count',
          trait: this.metadata.attribute_count,
          score: this.metadata.attribute_score,
          count: this.attributesCount.find((el) => el.attributesCount === this.metadata.attribute_count)?.vehashes,
        },
        ...mappedTraits,
      ];
    }

    return undefined;
  }

  public async mounted() {
    // this.metadata = await window
    //   .fetch(this.item.ipfsHash, { mode: 'cors' })
    //   .then((response) => response.json())
    //   .catch((err) => {
    //     console.error(err);
    //     return err;
    //   });

    // console.log(this.metadata);
  }
}
