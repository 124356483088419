















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { picasso } from '@vechain/picasso';
import * as common from '@/operations/common';

import * as blockchainMethod from '@/operations/blockchainMethod';

@Component({
  components: {},
})
export default class BaseCollection extends Vue {
  @Prop() item;

  public collectionBalance = 0;

}
