import AccountSign from '../types/AccountSign'
import State from '@/types/State';
import Env from '@/types/Env';
import Marketplace from '@/types/Marketplace';

export default {
  account(state: State): AccountSign {
    return state.account;
  },

  env(state: State): Env {
    return state.env;
  },

  marketplaceSortPriceLowHigh(state: State): Marketplace[] {
    return state.marketplace;
  },

  marketplaceItemsVET(state: State): Marketplace[] {
    return state.marketplace.filter((el) => el.payment.toLowerCase() === "vet")
  },

  marketplaceItemsWOV(state: State): Marketplace[] {
    return state.marketplace.filter((el) => el.payment.toLowerCase() === "wov")
  }

};
