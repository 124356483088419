// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
async function feeDelegationSignTx(connex, service: string, payer: string, signer: string, transferClause, comment: string){
  if (window.connex) {
    // Connex V1
    connex = window.connex;

    const delegationHandler = async unsignedTx => {
      // if no-cors body is not parse
      const sig = await window.fetch(service + 'V1/', {
        method: 'POST',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(unsignedTx),
      });
      const resp = await sig.json();
      return resp;
    };

    const transactionInfo = await connex.vendor
      .sign('tx')
      // .delegate(delegationHandler)
      .signer(signer)
      .request(transferClause);

    return transactionInfo;

  }else{
    // Connex V2
    // eslint-disable-next-line
    const signingService = connex.vendor.sign('tx', transferClause);
    signingService
      // .delegate(service + 'V2/', payer)
      .signer(signer) // Enforce signer
      .comment(comment);
    // eslint-disable-next-line
    const transactionInfo = await signingService.request([
      {
        comment: comment,
        ...transferClause,
      },
    ]);
    return transactionInfo;
  }
}

export {
  feeDelegationSignTx
};
