







import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as common from '@/operations/common';

@Component({
  components: {},
})
export default class BaseCard extends Vue {
  @Prop(Number) tokenId;

  public artistAccountName = '';
  public artistAccountStatus = false;

  @Watch('tokenId', { immediate: true }) async onTokenIdChanged(): Promise<void> {
    this.artistAccountName = await common
      .getTokenId(
        this.$connex,
        this.tokenId,
        this.$ENV.WOV_COMMUNITY_CARD_NFT
      )
      .then((response) => response.tokenCreator.slice(0, 6) + '...');
    this.artistAccountStatus = true;
  }
}
