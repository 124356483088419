




































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import * as common from '../../operations/common';

import AccountSign from '@/types/AccountSign';

import * as blockchainMethod from '../../operations/blockchainMethod';
import { bus } from '../../main';

import BaseModal from '../BaseModal.vue';

@Component({
  components: {},
})
export default class ModalSafeTransfer extends Vue {
  @Prop() smartcontractAddress;
  @Prop() item;
  @Prop() formTokenId;

  $refs!: {
    baseModal: BaseModal;
  };

  public isFiltering = 0;

  public formTransferTo = '';

  get accountSignCompute(): AccountSign {
    return this.$store.getters.account;
  }
  get payment(): string {
    return this.item.payment;
  }

  get price(): number {
    return this.item.price;
  }

  data(): { [key: string]: string } {
    return {
      modalKey: `ModalSafeTransferFor${this.item.tokenId}`,
    };
  }

  displayButton(): boolean | undefined {
    return true;
    // return this.accountSignCompute?.signedIn && this.isOnAuction() && this.isNotTokenOwner();
  }

  formatPrice(price: number): string {
    return Intl.NumberFormat('us-US', {
      maximumFractionDigits: 2,
    }).format(price / 1000000000000000000);
  }

  isOnAuction(): boolean {
    return common.isOnAuction(this.$store.state, this.item.tokenId, this.smartcontractAddress)[0].isOnAuction;
  }

  isNotTokenOwner(): boolean {
    return this.accountSignCompute.address != this.item.tokenOwner;
  }

  public async safeTransferFrom(event): Promise<void> {
    let result = await blockchainMethod.safeTransferFromWithFee(
      this.$connex,
      this.$VIP191SERVICE,
      this.$VIP191PAYER,
      this.smartcontractAddress,
      this.$ENV.WOV_VIP180,
      this.accountSignCompute.address,
      this.accountSignCompute.address,
      this.formTransferTo,
      this.formTokenId,
      'Transfer NFT'
    );
    bus.$emit('transferResult', result.txid);
    bus.$emit('showToast', true);
    event.preventDefault;
  }

  // @Watch('item', { immediate: true }) async onItemChanged(): Promise<void> {
  //   console.log(this.item);
  // }
}
