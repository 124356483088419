















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class BaseModal extends Vue {
  @Prop() buttonClasses;
  @Prop() buttonText;
  @Prop() modalTitle;
  @Prop() modalKey;

  data () {
    return {
      hideCancel: true
    }
  }
  showModal() {
    this.$bvModal.show(this.modalKey);
  }

  hideModal() {
    this.$bvModal.hide(this.modalKey);
  }
}
